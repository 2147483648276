import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['nameInput', 'nameHelpText', 'convertedName', 'formatting', 'models', 'modelFields']
  static values = {
    url: String,
    modelRegex: String
  }

  initialize() {
    this.oldModelFields = []
    this.choices = new window.Choices(this.modelFieldsTarget, {
      removeItems: true,
      removeItemButton: true,
      duplicateItemsAllowed: false,
      shouldSort: false
    })
  }

  connect() {
    this.oldModelFields = $(this.modelFieldsTarget).val()
    this.updateHelpText()
  }

  updateHelpText() {
    const nameValue = this.nameInputTarget.value.trim()

    if (nameValue != '') {
      $(this.convertedNameTarget).text(nameValue.toLowerCase().replaceAll(' ', '_'))
      $(this.nameHelpTextTarget).show()
    } else {
      $(this.nameHelpTextTarget).hide()
    }
  }

  handleModelschange() {
    const models = $(this.modelsTarget).val().join()
    const url = `${this.urlValue}${models}`
    fetch(url).then(response => response.json()).then(data => this.updateOptions(data))
  }

  updateOptions(data) {
    this.choices.setChoices(this.parseOptions(data), 'value', 'label', true)
    this.refreshModelFieldItem(data)
  }

  parseOptions(data) {
    return data.map(v => ({ value: v, label: v }))
  }

  refreshModelFieldItem(data) {
    $.each($(this.modelFieldsTarget).val(), (_idx, option) => {
      if (option && !data.includes(option)) {
        this.choices.removeActiveItemsByValue(option)
      }
    })

    this.oldModelFields = $(this.modelFieldsTarget).val()
    this.refreshFormatting()
  }

  handleModelFieldschange() {
    const newModelFields = $(this.modelFieldsTarget).val()
    const formattingValue = $(this.formattingTarget).val()
    const selectedModelField = newModelFields.filter(f => !this.oldModelFields.includes(f))[0]

    if (selectedModelField) {
      $(this.formattingTarget).val(`${formattingValue} {${selectedModelField}}`)
    }

    this.oldModelFields = newModelFields
    this.refreshFormatting()
  }

  refreshFormatting() {
    const modelFields = $(this.modelFieldsTarget).val()
    const matches = []
    const pattern = new RegExp(`\{(${this.modelRegexValue}).(.*?)\}`, 'g')
    let formattingValue = $(this.formattingTarget).val()
    let match

    while ((match = pattern.exec(formattingValue)) != null) {
      matches.push(match[0])
    }

    const invalidReplacementTexts = matches.filter(f => !modelFields.includes(f.replace(/[{}]/g, '')))

    invalidReplacementTexts.forEach(s => {
      formattingValue = formattingValue.replace(s, '')
    })

    $(this.formattingTarget).val(formattingValue.replace(/\s{2,}/g, ' ').trim())
  }
}
