import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['propertyEl', 'residentId']

  connect() {
    this.resetSession();
  }

  resetSession() {
    this.timer = setTimeout(function(){
      $.ajax({
        url: '/users/sign_out',
        method: 'delete'
      }).then(data => {
        alert('Your session has expired due to inactivity');
        window.location = '/users/sign_in';
      })
    }, 60000 * 15);
  }

  disconnect() {
    clearTimeout(this.timer);
  }

  updateRaapPrograms() {
    const propertyId = this.propertyElTarget.value
    const residentId = this.residentIdTarget.value

    if (propertyId !== '') {
      var url = ''
      if (residentId == '') {
        url = `/residents/raap_program_options/${propertyId}`
      }
      else {
        url = `/residents/${residentId}/raap_program_options/${propertyId}`
      }
      $.ajax({
        url: url,
        dataType: 'script'
      }).then(_data => {
        document.querySelector('#raap_program-email-templates-filter')["email-templates--filter"].handleFilterOptionsUpdate()
      })
    }
  }
}
