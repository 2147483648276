import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['callDispositionSelection', 'callDispositionReasonSelection', 'callDispositionReasonCollapse']

  updateOptions(data, blankText, selectionTarget) {
    let options = `<option value=''>${blankText}</option>`

    $.each(data, (k, v) => {
      options += `<option value=${v.id}>${v.name}</option>`
    })

    $(selectionTarget).empty().append(options)
  }

  updateCallDispositionReasonOptions(e) {
    let callDispositionId = this.callDispositionSelectionTarget.value

    if (callDispositionId === '') {
      $(this.callDispositionReasonCollapseTarget).hide()
      return
    }

    $.ajax({
      url: '/admin/call_disposition_reasons/load_call_disposition_reason_options',
      data: { call_disposition_id: callDispositionId },
      dataType: 'json'
    }).then(data => {
      if (data['call_disposition_reasons'].length === 0) {
        $(this.callDispositionReasonCollapseTarget).hide()
      }
      else {
        $(this.callDispositionReasonCollapseTarget).show()
        this.updateOptions(data['call_disposition_reasons'], e.target.dataset.blankText, this.callDispositionReasonSelectionTarget)
      }
    })
  }
}
