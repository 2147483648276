import { Controller } from 'stimulus'

import tinymce from 'tinymce/tinymce'
import 'tinymce/icons/default/icons'
import 'tinymce/themes/silver/theme'

// Import plugins
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/code'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/help'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/table'

export default class extends Controller {
  static targets = ['input']

  initialize() {
    this.defaults = {
      content_css: false,
      skin: false,
      toolbar: ['styleselect | bold italic underline strikethrough superscript | forecolor backcolor blockquote link | numlist bullist | alignleft aligncenter alignright | table | fullscreen preview | undo redo | code help'],
      plugins: 'advlist link lists fullscreen help preview table code autoresize',
      menubar: false,
      default_link_target: '_blank',
      link_title: false,
      link_context_toolbar: true,
      element_format: 'html',
      min_height: 400,
      resize: true
    }
  }

  connect() {
    let config = Object.assign({ target: this.inputTarget }, this.defaults)
    tinymce.init(config)
  }

  disconnect() {
    tinymce.remove()
  }
}
