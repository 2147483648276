import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["trigger", "triggerValue", "secondaryTrigger", "secondaryTriggerValue"]

  updateOptionsEl(data, element) {
    let options = "<option value=''>-- Select One --</option>"
    if (!Array.isArray(data)) {
      data = Object.entries(data)
    }

    $.each(data, (k, v) => {
      options += `<option value=${v[0]}>${v[1]}</option>`
    })

    $(element).empty().append(options)
  }

  updateOptions(triggerValue, url, optionsEl) {
    if (triggerValue !== '') {
      $.ajax({
        url: url,
        dataType: 'json'
      }).then(data => {
        this.updateOptionsEl(data, optionsEl)
      })
    } else {
      this.updateOptionsEl([], optionsEl)
    }
  }

  handleTriggerOnchange() {
    const trigger = this.triggerTarget.value

    this.updateOptions(trigger, `/admin/email_templates/load_triggers?type=trigger_values&trigger=${trigger}`, this.triggerValueTarget)
    this.updateOptions(trigger, `/admin/email_templates/load_triggers?type=secondary_triggers&trigger=${trigger}`, this.secondaryTriggerTarget)
    this.cleanUpOptions(this.secondaryTriggerValueTarget)
  }

  cleanUpOptions(ele) {
    let options = "<option value=''>-- Select One --</option>"
    $(ele).empty().append(options)
  }

  handleSecondaryTriggerOnChange() {
    const trigger = this.secondaryTriggerTarget.value
    const url = `/admin/email_templates/load_triggers?type=secondary_trigger_values&secondary_trigger=${trigger}&trigger_value=${this.triggerValueTarget.value}`
    this.updateOptions(trigger, url, this.secondaryTriggerValueTarget)
  }
}
