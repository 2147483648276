import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["companyEl", "owner", "region", "autoSyncSwitcher"]

  updateOptions(target, data) {
    let options = "<option value=''>-- Select One --</option>"

    $.each(data, (k, v) => {
      options += `<option value=${v.id}>${v.name}</option>`
    })

    $(target).empty().append(options)
  }

  updateOwnersAndRegions() {
    const managementCompanyId = this.companyElTarget.value

    if (managementCompanyId !== '') {
      $.ajax({
        url: `/admin/companies/${managementCompanyId}/owners_and_regions`,
        dataType: 'json'
      }).then(data => {
        this.updateOptions(this.ownerTarget, data['owners'])
        this.updateOptions(this.regionTarget, data['regions'])
      })
    } else {
      this.updateOptions(this.ownerTarget, [])
      this.updateOptions(this.regionTarget, [])
    }
  }

  updateAutoSync() {
    const switcherEl = this.autoSyncSwitcherTarget
    const propertyId = this.autoSyncSwitcherTarget.dataset.propertyid

    $.ajax({
      url: `/admin/properties/${propertyId}/update_auto_sync`,
      method: 'put',
      dataType: 'json'
    }).then(data => {
      switcherEl.checked = data.status
    })
  }
}
