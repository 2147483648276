import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['value', 'description', 'checkbox', 'checkboxAll']

  initialize() {
    this.displayNameCount = 5
    this.checkedIds = []
  }

  connect() {
    this.element[this.identifier] = this
    this.checkedIds = this.valueTarget.value.split(',').filter(e => e)
    this.updateDescription()
  }

  handleCheckboxClick(e) {
    if (e.target.value === 'all') {
      $(this.checkboxTargets).prop('checked', $(e.target).prop('checked'))
    } else {
      $(this.checkboxAllTarget).prop('checked', false)
    }

    this.handleFilterOptionsUpdate()
  }

  handleFilterOptionsUpdate() {
    this.checkedIds = []

    $(this.checkboxTargets).each((i, e) => {
      if ($(e).is(':checked')) {
        this.checkedIds.push($(e).val())
      }
    })

    this.updateValue()
    this.updateDescription()
  }

  updateValue() {
    this.valueTarget.value = this.getValue()

    if (this.valueTarget.value === 'all') {
      $(this.checkboxAllTarget).prop('checked', true)
    } else if (this.valueTarget.value === '') {
      $(this.checkboxAllTarget).prop('checked', false)
    }

    if (this.valueTarget.dataset.action) {
      setTimeout(() => {
        this.dispatchChangeEvent()
      }, 1000)
    }
  }

  getValue() {
    if (this.checkedIds.length === 0) {
      return ''
    } else if ($(this.checkboxTargets).length === this.checkedIds.length){
      return 'all'
    } else {
      return this.checkedIds.join()
    }
  }

  updateDescription() {
    const data = this.loadDescriptionData()
    let desc = ''

    $.each(data, (k, v) => {
      desc += `<span class="badge me-1 mb-1 bg-200 text-700 fs--1 lh-sm col-auto">${v}</span>`
    })

    $(this.descriptionTarget).empty().append(desc)
  }

  loadDescriptionData() {
    let arr = []

    if (this.checkedIds.length === 0) {
      arr.push('None')
    } else if (this.checkedIds.includes('all') || $(this.checkboxTargets).length === this.checkedIds.length) {
      arr.push('All')
    } else if (this.checkedIds.length > this.displayNameCount) {
      arr.push(`Multiple (${this.checkedIds.length})`)
    } else {
      $(this.checkboxTargets).each((i, e) => {
        if ($(e).is(':checked')) {
          arr.push($(e).siblings('label').text())
        }
      })
    }

    if (arr.length == 0) {
      arr.push('None')
    }

    return arr
  }

  dispatchChangeEvent() {
    let event = new Event('change')
    this.valueTarget.dispatchEvent(event)
  }

  selectAll() {
    this.checkedIds = this.valueTarget.value.split(',').filter(e => e)
    $(this.checkboxAllTarget).prop('checked', true)
    $(this.checkboxTargets).prop('checked', true)
    this.updateDescription()
  }
}
