import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["value"]

  copyToClipboard() {
    var value = $(this.valueTarget)[0].innerText;
    var tempInput = document.createElement("input");
    tempInput.value = value;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  }
}
