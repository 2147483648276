// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
window.bootstrap = require("bootstrap")
import "@fortawesome/fontawesome-free/js/all"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

var jQuery = require('jquery')
global.$ = global.jQuery = jQuery
window.$ = window.jQuery = jQuery

require("bootstrap-datepicker")
require("@nathanvda/cocoon")

jQuery(document).on("turbolinks:load", function() {
  jQuery('.datepicker').datepicker({
    todayBtn: 'linked'
  });

  jQuery('.input-group-datepicker > .input-group-append').on('click', function(e) {
    const parent = jQuery(this).parents('.input-group')[0]
    jQuery('.datepicker', parent)[0].focus()
  });

  jQuery('[data-toggle="tooltip"]').tooltip();
});

import "controllers"