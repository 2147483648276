import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    url: String
  }

  goToSortableUrl() {
    window.location.href = this.urlValue
  }
}
