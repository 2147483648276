import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['select']

  initialize() {
    new window.Choices(this.selectTarget, {
      removeItems: true,
      removeItemButton: true
    })
  }
}
