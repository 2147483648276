import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['form', 'previewButton']

  getFilterParamName(itemName) {
    return itemName.match(/\[(.*?)\]/)[1]
  }

  previewResidents(e) {
    e.preventDefault()

    let url = `${$(this.previewButtonTarget).attr('href')}?`
    const params = {}

    $.each($(this.formTarget).serializeArray(), (index, item) => {
      if (item.name.startsWith('agent_queue')) {
        let param = this.getFilterParamName(item.name)

        if (param != 'name' && param != 'user_ids') {
          if (!params[param]) params[param] = []

          if (item.value && item.value != '') {
            params[param].push(item.value)
          }
        }
      }
    })

    $.each(params, (k, v) => {
      let paramValue = v.join()

      if (k == 'timezone_array' || k == 'state_array') {
        paramValue = encodeURIComponent(paramValue)
      }

      url += `${k}=${paramValue}&`
    })

    window.open(url)
  }
}
