import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["systemSelection", "propertySelection", "hostnameInput", "databaseInput", "syncBtn", ""]

  initialize() {
    try {
      this.systemSelectionTarget;
      this.updateForm();
    } catch (error) {}
  }

  updateForm() {
    const selectedSystem = this.systemSelectionTarget.value;

    const urlParams = new URLSearchParams(window.location.search)
    const systemParam = urlParams.get('integration_setting[property_management_system]')

    if (selectedSystem && systemParam != selectedSystem) {
      document.location.href = location.protocol + '//' + location.host + location.pathname + "?integration_setting[property_management_system]=" + selectedSystem;
    }
  }

  changeSyncButton() {
    const settingId = this.syncBtnTarget.dataset.settingid
    $(`#sync-now-button-${settingId} span`).text("Syncing")
    $(`#sync-now-button-${settingId} svg`).removeClass("d-none")
  }
}
