import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['owners', 'managementCompanies', 'properties']

  handleOwnersChange() {
    let url = `/admin/email_templates/company_filter_options?owners=${this.ownersTarget.value}`
    url += `&companies=${this.managementCompaniesTarget.value}`

    $.ajax({
      url: url,
      dataType: 'script'
    }).then(_data => {
      document.querySelector('#company-email-templates-filter')["email-templates--filter"].handleFilterOptionsUpdate()
    })
  }

  handleManagementCompaniesChange() {
    let url = `/admin/email_templates/property_filter_options?owners=${this.ownersTarget.value}`
    url += `&companies=${this.managementCompaniesTarget.value}`
    url += `&properties=${this.propertiesTarget.value}`

    $.ajax({
      url: url,
      dataType: 'script'
    }).then(_data => {
      document.querySelector('#property-email-templates-filter')["email-templates--filter"].handleFilterOptionsUpdate()
    })
  }
}
