import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["deliverTo", "emailTypes", "owners", "companies", "properties", "ownersValue", "companiesValue"]

  changeDeliverTo() {
    const deliverTo = this.deliverToTarget.value
    this.toggleFilter(this.ownersTarget, ['resident_only', 'owner_only'], deliverTo)
    this.toggleFilter(this.companiesTarget, ['resident_only', 'operator_only'], deliverTo)
    this.toggleFilter(this.propertiesTarget, ['resident_only', 'community_only'], deliverTo)
    this.toggleEmailTypes(deliverTo)
    this.updateFilterOptions(deliverTo)
  }

  toggleFilter(target, options, deliverTo) {
    if (deliverTo == 'all' || options.some(option => deliverTo.includes(option))) {
      $(target).show()
    } else {
      $(target).hide()
    }
  }

  toggleEmailTypes(deliverTo) {
    if (deliverTo == 'all' || deliverTo.includes('resident_only')) {
      $(this.emailTypesTarget).hide()
    } else {
      $(this.emailTypesTarget).show()
    }
  }

  updateFilterOptions(deliverTo) {
    let event = new Event('change')

    if (deliverTo == 'all' || deliverTo.includes('community_only')) {
      this.selectAllOwners()
      this.selectAllCompanies()
      this.ownersValueTarget.dispatchEvent(event)
      this.companiesValueTarget.dispatchEvent(event)
    } else if (deliverTo.includes('resident_only')) {
      this.ownersValueTarget.dispatchEvent(event)
    } else if (deliverTo.includes('operator_only')) {
      this.selectAllOwners()
      this.ownersValueTarget.dispatchEvent(event)
    }
  }

  selectAllOwners() {
    $(this.ownersValueTarget).val('all')
    document.querySelector('#owner-email-templates-filter')["email-templates--filter"].selectAll()
  }

  selectAllCompanies() {
    $(this.companiesValueTarget).val('all')
    document.querySelector('#company-email-templates-filter')["email-templates--filter"].selectAll()
  }
}
