import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['owner', 'company', 'property']
  static values = {
    url: String
  }

  initialize() {
    this.options = {
      removeItems: true,
      removeItemButton: true,
      duplicateItemsAllowed: false,
      shouldSort: false
    }

    this.companyChoices = new window.Choices(this.companyTarget, this.options)
    this.propertyChoices = new window.Choices(this.propertyTarget, this.options)
  }

  handleOwnerChange() {
    const ownerIds = $(this.ownerTarget).val().join()
    const url = `${this.urlValue}?filter=company&owner_ids=${ownerIds}`
    fetch(url)
      .then(response => response.json())
      .then(data => this.updateOptions(this.companyTarget, this.companyChoices, data))
      .then(() => this.handleCompanyChange())
  }

  handleCompanyChange() {
    const ownerIds = $(this.ownerTarget).val().join()
    const companyIds = $(this.companyTarget).val().join()
    const url = `${this.urlValue}?filter=property&owner_ids=${ownerIds}&company_ids=${companyIds}`
    fetch(url)
      .then(response => response.json())
      .then(data => this.updateOptions(this.propertyTarget, this.propertyChoices, data))
  }

  updateOptions(target, choices, data) {
    const selectedValues = $(target).val().map(id => parseInt(id))
    choices.removeActiveItems()
    choices.setChoices(data, 'value', 'label', true)
    choices.setChoiceByValue(selectedValues)
  }
}
