import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["companySelection", "propertySelection", "dispositionSelection", "dispositionReasonSelection"]

  updateOptions(data, blankText) {
    const selectedProperty = this.propertySelectionTarget.value
    let options = `<option value=''>${blankText}</option>`

    $.each(data, (k, v) => {
      options += `<option value=${v.id} ${selectedProperty == v.id ? 'selected' : ''}>${v.name}</option>`
    })

    $(this.propertySelectionTarget).empty().append(options)
  }

  updatePropertyOptions(e) {
    $.ajax({
      url: '/load_properties/',
      data: { company_id: this.companySelectionTarget.value },
      dataType: 'json'
    }).then(data => {
      this.updateOptions(data['properties'], e.target.dataset.blankText)
    })
  }

  updateCallDispositionReasonOptions(e) {
    $.ajax({
      url: '/load_disposition_reasons/',
      data: { disposition_id: this.dispositionSelectionTarget.value },
      dataType: 'json'
    }).then(data => {
      this.updateDispositionReasonOptions(data['properties'], e.target.dataset.blankText)
    })
  }

  updateDispositionReasonOptions(data, blankText) {
    const selectedDispositionReason = this.dispositionReasonSelectionTarget.value
    let options = `<option value=''>${blankText}</option>`

    $.each(data, (k, v) => {
      options += `<option value=${v.id} ${selectedDispositionReason == v.id ? 'selected' : ''}>${v.name}</option>`
    })

    $(this.dispositionReasonSelectionTarget).empty().append(options)
  }
}
